import { Link } from "react-router-dom";

const Vector: React.FC<any> = ({
  id,
  className,
  itemBackground,
  vector,
  vecLocation,
  title,
  description,
  content,
  pdf,
}: {
  id: string;
  className: string;
  itemBackground: string;
  vector: any;
  vecLocation: string;
  title: string;
  description: string;
  content: any;
  pdf: string;
}) => {
  return (
    <div id={id} className={`w-full  cursor-default ${className}`}>
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div>
          <div className="w-full flex flex-col md:flex-row items-center gap-16 mb-6">
            {vecLocation === "start" && (
              <img className="w-1/3" src={vector} alt="" />
            )}

            <div className="w-1/2 flex flex-col">
              <div className="text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
                <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  {title}
                </div>
              </div>

              <div className="text-justify text-dark/50 text-lg my-4">
                <span className="text-lg font-normal">{description}</span>
              </div>
            </div>

            {vecLocation === "end" && (
              <img className="w-1/3" src={vector} alt="" />
            )}
          </div>

          <ul className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
            {content.map((item: any, index: number) => (
              <li
                key={index}
                className={`h-auto bg-${itemBackground} p-6 rounded-t-xl rounded-bl-xl drop-shadow-darkBlue transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ${
                  index === content.length - 1 && index % 2 === 0
                    ? "md:col-span-2"
                    : "col-span-1"
                }`}
              >
                <div className="w-7 -ml-8 -mt-8 bg-primary rounded-t-md rounded-bl-md flex justify-center items-center">
                  <div className="text-lightGray text-lg lg:text-xl font-semibold">
                    {item.number}
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <div className="text-dark text-lg lg:text-xl font-bold capitalize leading-relaxed">
                    {item.title}
                  </div>
                </div>

                <div className="text-justify text-dark/50 text-base font-normal mt-4 leading-[21.79px]">
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-row gap-4 mt-8">
          <Link
            to={`/contact?subject=${title}#contactForm`}
            className="w-full h-[54px] my-4 p-4 bg-primary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
          >
            <span className="text-lightGray hover:text-white text-base lg:text-lg font-normal uppercase">
              Request a Quote
            </span>
          </Link>

          <a
            href={`/Sheets/${pdf}`}
            download={pdf}
            className="w-full h-[54px] my-4 p-4 bg-secondary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
          >
            <button className="text-lightGray hover:text-white text-base lg:text-lg font-normal uppercase">
              Download PDF
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Vector;
